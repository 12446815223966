import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import axios from "axios";
import ButtonIcon from "./ocho_button.png";
import ChatbotLogo from "./chadbot_logo.png";

// Ensure the FULL_API_URL is defined, with a fallback if undefined
// First, explicitly set the FULL_API_URL based on the environment.
const FULL_API_URL = process.env.REACT_APP_FULL_API_URL || 'https://tsc.chat';

// Log a warning if FULL_API_URL is still undefined for some reason.
if (!FULL_API_URL) {
  console.error("FULL_API_URL is not defined. Please check your environment variables and deployment setup.");
} else {
  console.log("Using FULL_API_URL:", FULL_API_URL);
}


function App() {
  const [messages, setMessages] = useState([
    {
      role: "assistant",
      content: "What's up, Buttercup? Welcome to TSC! We used to be The Starr Conspiracy, but hey ... change and stuff ... you know what I mean? Anyway, before you ask ... yes, this is our website. I'm chaDbot. I'm a lot like a chatbot, but lazier and less reliable. So whatcha want?",
      timestamp: new Date().toISOString(),
    },
  ]);
  const [input, setInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [showPrompts, setShowPrompts] = useState(true);
  const [conversationState, setConversationState] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    topic: "",
  });
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);

  // Prompts array for button rendering
  const prompts = [
    {
      headline: "Contact me",
      description: "Have someone from TSC reach out",
    },
    // Additional prompts can be added here
  ];

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const adjustContainerHeight = () => {
    if (containerRef.current) {
      const windowHeight = window.innerHeight;
      containerRef.current.style.height = `${windowHeight - 20}px`;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
  }, []);

  useEffect(() => {
    adjustContainerHeight();
    window.addEventListener("resize", adjustContainerHeight);
    return () => window.removeEventListener("resize", adjustContainerHeight);
  }, []);

  const getTimeOfDay = (date) => {
    const hours = date.getHours();
    if (hours < 12) return "morning";
    if (hours < 18) return "afternoon";
    return "evening";
  };

  useEffect(() => {
    const fetchUserSession = async () => {
      try {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await axios.get(`${FULL_API_URL}/api/check-session`, {
          headers: {
            'X-User-Timezone': userTimezone,
          },
        });

        if (response.data.isRecognized) {
          const { name } = response.data;
          const currentTime = new Date();
          const userTimeOfDay = getTimeOfDay(currentTime);

          const greetingMessage = `Good ${userTimeOfDay}, ${name}!`;

          setMessages((prevMessages) => [
            {
              role: "assistant",
              content: greetingMessage,
              timestamp: new Date().toISOString(),
            },
            ...prevMessages,
          ]);
        }
      } catch (error) {
        console.error("Error fetching user session:", error);
      }
    };

    const fetchTodaysHoliday = async () => {
      try {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await axios.get(`${FULL_API_URL}/api/todays-holiday`, {
          params: {
            timezone: userTimezone,
          },
        });

        console.log('API response for holiday:', response.data);

        const holidayMessage = `By the way, happy ${response.data.holiday}!!!`;
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            role: "assistant",
            content: holidayMessage,
            timestamp: new Date().toISOString(),
          },
        ]);
      } catch (error) {
        console.error("Error fetching today's holiday:", error);
      }
    };

    fetchUserSession();
    fetchTodaysHoliday();
  }, [FULL_API_URL]);

  useEffect(() => {
    const testBackendConnection = async () => {
      try {
        const response = await axios.get(`${FULL_API_URL}/api/test`);
        console.log("Backend response:", response.data);
      } catch (error) {
        console.error("Error connecting to backend:", error);
      }
    };

    testBackendConnection();
  }, [FULL_API_URL]);

  const validatePhoneNumber = (phone_number) => {
    const normalizedNumber = phone_number.replace(/\D/g, '');
    const re = /^\d{10}$/;
    return re.test(normalizedNumber);
  };

  const formatPhoneNumber = (phone_number) => {
    return phone_number.replace(/\D/g, '');
  };

  const handleSend = async (inputText = input) => {
    if (!inputText.trim()) return;

    setShowPrompts(false);
    const newMessage = {
      role: "user",
      content: inputText,
      timestamp: new Date().toISOString(),
    };
    const newMessages = [...messages, newMessage];
    setMessages(newMessages);
    setInput("");
    setIsTyping(true);

    let botReply = "";

    try {
      if (conversationState === "collectingName") {
        botReply = `Pleasure to meet you, ${inputText}! Do you prefer email or text?`;
        setFormData({ ...formData, name: inputText });
        setConversationState("askForContactMethod");
      } else if (conversationState === "askForContactMethod") {
        if (inputText.toLowerCase().includes("text")) {
          botReply = "Okay you crazy Gen Zer. Text it is. What’s your phone number?";
          setConversationState("collectingPhoneNumber");
        } else if (inputText.toLowerCase().includes("email")) {
          botReply = "Right on. Texting is not for you. I totally get it. What’s your email address?";
          setConversationState("collectingEmail");
        }
      } else if (conversationState === "collectingPhoneNumber" && validatePhoneNumber(inputText)) {
        const formattedPhoneNumber = formatPhoneNumber(inputText);
        botReply = "Awesome. Someone from TSC will text you soon! Or at least soon-ish?";
        setFormData({ ...formData, phone_number: formattedPhoneNumber });
        setConversationState("collectingTopic");
        setMessages([...newMessages, {
          role: "assistant",
          content: botReply,
          timestamp: new Date().toISOString(),
        }, {
          role: "assistant",
          content: "Oh wait. One last thing. They get mad when I don't ask. What do you want to chat with TSC about?",
          timestamp: new Date().toISOString(),
        }]);
        setIsTyping(false);
        return;
      } else if (conversationState === "collectingEmail" && validateEmail(inputText)) {
        botReply = "Awesome. Someone from TSC will email you soon. Or at least soon-ish?";
        setFormData({ ...formData, email: inputText });
        setConversationState("collectingTopic");
        setMessages([...newMessages, {
          role: "assistant",
          content: botReply,
          timestamp: new Date().toISOString(),
        }, {
          role: "assistant",
          content: "Oh wait. One last thing. They get mad when I don't ask. What do you want to chat with TSC about?",
          timestamp: new Date().toISOString(),
        }]);
        setIsTyping(false);
        return;
      } else if (conversationState === "collectingTopic") {
        botReply = "Got it! I’ve texted everyone at TSC I can find a number for! Bet that gets their attention. Someone will reach out to you soon.";
        const updatedFormData = { ...formData, topic: inputText };
        await axios.post(`${FULL_API_URL}/api/contact`, updatedFormData);
        setConversationState(null);
      } else if (inputText.toLowerCase().includes("contact me")) {
        botReply = "I can have someone reach out. Let me write down your info. What's your name? Just type it in. No funny business, Ms. Amanda Hugandkiss.";
        setConversationState("collectingName");
      } else if (inputText.toLowerCase().includes("open tomorrow")) {
        const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const response = await axios.get(`${FULL_API_URL}/api/is-open-tomorrow`, {
          params: {
            timezone: userTimezone,
          },
        });
        const { isOpen, holiday } = response.data;
        botReply = `TSC is ${isOpen ? "open" : "closed"} tomorrow. By the way, it's ${holiday} tomorrow!`;
      } else {
        const response = await axios.post(`${FULL_API_URL}/api/chat`, {
          messages: newMessages,
        });
        botReply = response.data.reply;
      }
    } catch (error) {
      botReply = "Sorry, chaDbot does not compute.";
      console.error("Error connecting to backend:", error);
    }

    const assistantMessage = {
      role: "assistant",
      content: botReply,
      timestamp: new Date().toISOString(),
    };
    setMessages([...newMessages, assistantMessage]);
    setIsTyping(false);
  };

  const validateEmail = (email) => /\S+@\S+\.\S+/.test(email);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const renderMessageContent = (msg) => {
    const timeString = new Date(msg.timestamp).toLocaleTimeString();
    return (
      <div>
        {msg.role === "assistant" ? (
          <div dangerouslySetInnerHTML={{ __html: msg.content }} />
        ) : (
          <div>{msg.content}</div>
        )}
        <div className="timestamp">{timeString}</div>
      </div>
    );
  };

  return (
    <div className="App" ref={containerRef}>
      <div className="chat-container">
        <div className="messages">
          {messages.map((msg, index) => (
            <div
              key={index}
              className={
                msg.role === "user" ? "user-message" : "assistant-message"
              }
            >
              {renderMessageContent(msg)}
            </div>
          ))}
          {isTyping && (
            <div className="typing-indicator">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {showPrompts && (
          <>
            <div className="chatbot-image-container">
              <img
                src={ChatbotLogo}
                alt="Chatbot Logo"
                className="chatbot-image"
              />
            </div>
            <div className="prompt-buttons">
              {prompts.map((prompt, index) => (
                <button
                  key={index}
                  onClick={() => handleSend(prompt.headline)}
                  className="prompt-button"
                >
                  <div className="prompt-headline">{prompt.headline}</div>
                  <div className="prompt-description">{prompt.description}</div>
                </button>
              ))}
            </div>
          </>
        )}

        <div className="input-container">
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Type message ..."
            className="message-input"
            rows="4"
          />
          <button className="send-button" onClick={() => handleSend(input)}>
            <img src={ButtonIcon} alt="Send" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default App;
